/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// stylesheet assets
require(/* webpackPrefetch: true */ '../stylesheets/application.css.scss')

import "core-js/stable";
import "regenerator-runtime/runtime";
import "@fortawesome/fontawesome-free/css/all";

import moment from 'moment';
window.moment = moment;

import 'moment-timezone';

import $ from 'jquery';
global.$ = $;
global.jQuery = $;

import tether from 'tether';
global.Tether = tether;
global.Tether = require('tether');

import Cookies from 'js-cookie';
global.Cookies = Cookies;
global.Cookies = require('js-cookie');


import youbora from 'youboralib';
global.youbora = youbora;
window.youbora = youbora;
require('youbora-adapter-videojs');

import videojs from 'video.js';
global.videojs = videojs;

require("@rails/ujs").start()
require("jquery")
require("moment")
require("slick")
require("bootstrap/dist/js/bootstrap")
require("video.js")
require("perfect-scrollbar")

require("../scripts/jquery.countdown.min")
require("../scripts/jquery.oembed.js")
require("../scripts/initial")
require("../scripts/sidenav.js")
require("../scripts/chartjs.js")
require("../scripts/dashboards_dashboard-1.js")
require("../scripts/layout-helpers.js")
require("../scripts/pace.js")
require("../scripts/polyfills.js")
require("../scripts/popper.js")
require("../scripts/theme-settings.js")
require("../scripts/demo.js")
require("../scripts/perfect-scrollbar")
