$(document).ready(function () {
  $(".dropdown").click(function () {
    if ($(this).find(".dropdown-menu:visible").length == 0) {
      $(this).find(".dropdown-menu").css("display", "block");
    } else {
      $(this).find(".dropdown-menu").css("display", "none");
    }
  });
	
// sets the live chat height to match video height on load	
	function chatSetHeight() {
		setTimeout( function(){ 
     		var tallness = $(".graphic").height();
			var smallness = $(".chatAlert").height();
			var tallsmall = tallness - smallness;
			if(window.innerWidth < 1200) {
			  $("#talkjs-container").height(tallness*2);
			  $("#talkjs-container").css({"maxHeight":"600px"})
			}else{
			  $("#talkjs-container").height(tallness);
			  $("#talkjs-container").height(tallsmall);
			  $("#talkjs-container").css({"maxHeight":"600px"})
			}		
  		}  , 900 );
	}
	// set height of chat when nav is clicked
	$('.layout-sidenav-toggle').click(function () {
    	chatSetHeight();
  	});
	$( window ).resize(function() {
	  chatSetHeight();
	});
    chatSetHeight();
	
	
	
	
  setTimeout(function () {
    $(".alert").not("#we-messed-up-alert").fadeOut("slow");
  }, 5000);

  $(".navbar-toggler").click(function () {
    $(".navbar-collapse").toggleClass("show");
  });

  $('#landingPageBtn').click(function () {
    var lpfighter = $(this).attr('class');
    //console.log(lpfighter + " lpfighter");
    gtag('event', 'Affiliate', {
      'action': 'Clicked',
      'fighterName': lpfighter
    });
  });
	
	
	window.focus();
	window.addEventListener('blur', function(e){
	if(document.activeElement == document.getElementById('vCta'))
		{
			console.log('iframe click!');
		}
	});
	
	
  $('.subActCTA').click(function () {
    gtag('event', 'Login', {
      'action': 'Clicked',
      'page': 'Home Page'
    });

  });

  $('.newSub').click(function () {
    var eventClick = $('.pullTitle').text();
    gtag('event', 'Gated Content', {
      'action': 'Clicked',
      'page': eventClick
    });

  });
	
	$('#tab1').click(function () {
    gtag('event', 'Stats', {
      'action': 'Clicked',
      'button': 'Fight Results'
    });

  });
	$('#tab2').click(function () {
    gtag('event', 'Stats', {
      'action': 'Clicked',
      'button': 'Totals'
    });

  });
	$('#tab3').click(function () {
    gtag('event', 'Stats', {
      'action': 'Clicked',
      'button': 'Punches Thrown'
    });

  });
	$('#tab4').click(function () {
    gtag('event', 'Stats', {
      'action': 'Clicked',
      'button': 'Punched Landed'
    });

  });
	$('#tab5').click(function () {
    gtag('event', 'Stats', {
      'action': 'Clicked',
      'button': 'Knockdowns'
    });

  });
	
	$('.graphic').click(function () {
		gtag('event', 'Fastchannel', {
			'action': 'Clicked',
			'button': 'Fast Channel Play'
		});
	});
	
	$('#tabs li a').click(function(e){
		e.preventDefault();
		var t = $(this).attr('id'); 
		var tc = $(this).attr('id') + 'c';
		$('#tabs li a').removeClass('active');           
		$(this).addClass('active');

		$('.container').removeClass('active');
		$('#' + tc ).addClass('active');
	});
	


});
